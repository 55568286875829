
import { defineComponent, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { initializePopper } from '@/components/commentary/popper';
import SectionHeader from '@/components/global/SectionHeader.vue';
import { getCommentary, commentary } from '@/api/commentary';
import { isLoaded, notFound } from '@/interfaces/RemoteData';
import SuraNavigation from '@/components/commentary/SuraNavigation.vue';
import Popper from '@/components/commentary/Popper.vue';
import NotFound from '@/components/commentary/NotFound.vue';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import CommentaryArticle from '@/components/commentary/CommentaryArticle.vue';
import TableOfContents from '@/components/global/TableOfContents.vue';
import { toTableOfContents } from '@/interfaces/Commentary';
import { commentaryEra } from '@/components/commentary/suraClassification';
import HowToQuote from '@/components/global/HowToQuote.vue';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ArticleContainer,
    SectionHeader,
    CommentaryArticle,
    NotFound,
    Popper,
    SuraNavigation,
    TableOfContents,
    HowToQuote,
  },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();
    const pagetitle = computed(() => {
      if (isLoaded(commentary.value)) {
        return commentary.value.payload.title;
      }
      return '';
    });
    const title = computed(
      () => `${pagetitle.value} > ${t('navigation.commentary')} ${t('global.sura')} ${route.params.sura} ${t(
        'global.verse',
      )} ${route.params.verse}`,
    );
    const description = computed(
      () => `${pagetitle.value} > ${t('navigation.commentary')} ${t('global.sura')} ${route.params.sura} ${t(
        'global.verse',
      )} ${route.params.verse}`,
    );
    const canonical = computed(
      () => `https://corpuscoranicum.de/${route.params.lang}/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/commentary/`,
    );
    const alternate_de = computed(
      () => `https://corpuscoranicum.de/de/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/commentary/`,
    );
    const alternate_en = computed(
      () => `https://corpuscoranicum.de/en/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/commentary/`,
    );
    const alternate_fr = computed(
      () => `https://corpuscoranicum.de/fr/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/commentary/`,
    );
    const meta = computed(() => ({
      title: title.value,
      description: description.value,
      link: [
        {
          rel: 'canonical',
          href: canonical.value,
        },
        {
          rel: 'alternate',
          hreflang: 'de',
          href: alternate_de.value,
        },
        {
          rel: 'alternate',
          hreflang: 'en',
          href: alternate_en.value,
        },
        {
          rel: 'alternate',
          hreflang: 'fr',
          href: alternate_fr.value,
        },
        {
          rel: 'alternate',
          hreflang: 'x-default',
          href: alternate_de.value,
        },
      ],
      htmlAttrs: { lang: route.params.lang },
    }));
    useMeta(meta);
    getCommentary(+route.params.sura);

    watch(
      () => route.params.sura,
      () => {
        if (route.name === 'VerseCommentary') {
          getCommentary(+route.params.sura);
        }
      },
    );

    onMounted(() => {
      initializePopper();
    });

    return {
      isLoaded,
      notFound,
      commentary,
      toTableOfContents,
      commentaryEra,
    };
  },
});
